
import MainPage from "./pages/MainPage";


function App() {


  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
